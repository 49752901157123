import React from 'react'
import styled from 'styled-components'

const Text = styled.h1`

  position: fixed;
  padding: 2%;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  color:${props => `rgba(${props.theme.textRgba},0.3)`};
  font-size: calc(4rem + 4vw);
  z-index:0;

  @media (max-width: 768px) {
    font-size: calc(2rem + 3vw); /* Adjust font size for smaller screens */
  }
`

const BigTitlte = (props) => {
    return (
        <Text top={props.top} left={props.left} right={props.right}>
            {props.text}
        </Text>
    )
}

export default BigTitlte
