import React from 'react';
import styled from 'styled-components';
import { DarkTheme } from '../components/Themes';

const Logo = styled.h1`
    display: flex;
    color: ${props =>
        props.color === 'dark' ? DarkTheme.text : DarkTheme.body};
    font-family: 'Pacifico', cursive;
    position: fixed;
    left: 2%;
    top: 5%;
    z-index: 3;

    @media (max-width: 768px) {
        /* Adjust styles for screens up to 768px wide */
        font-size: 1.5rem;
    }

    @media (max-width: 480px) {
        /* Further adjustments for screens up to 480px wide */
        font-size: 1rem;
    }
`;

const LogoComponent = (props) => {
    return <Logo color={props.theme}>HB</Logo>;
};

export default LogoComponent;
