import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from './Themes';
import { Design, Develope, Developement, Additional_Skills} from './AllSvgs';


import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitlte_2'
import BigTitlte2 from '../subComponents/BigTitlte_2';
import SoundBar from '../subComponents/SoundBar';

const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height:auto;
position: relative;
display: flex;
justify-content: space-evenly;
align-items: center;
// flex-direction: column;
padding-top: 5rem;
flex-wrap: wrap; /* Allow items to wrap to the next row */



@media (max-width: 768px) {

    background-color: ${props => props.theme.body};
width: auto;
height:auto;
position: relative;
flex-direction: column;
display: flex;
justify-content: space-evenly;
align-items: center;
padding: 5rem;

}



`

const Main = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(32vw, 1fr)); /* Adjust column width as needed */
gap: 2rem; /* Set the gap between Main components */
margin-top: 1rem; /* Add some top margin to separate from the content above */
margin-bottom: 1rem; /* Add some top margin to separate from the content above */

border: 2px solid ${props => props.theme.text};
color: ${props => props.theme.text};
background-color: ${props => props.theme.body};
padding: 2rem;
width: 32vw;
height: 85vh;
z-index:0;
// line-height: 1.5;
cursor: pointer;

line-height: 2;
font-weight: 800;

font-family: 'Ubuntu Mono',monospace;
display: flex;
flex-direction: column;
justify-content: flex-start; /* Align text to the top */

&:hover{
    color: ${props => props.theme.body};
    // background-color: ${props => props.theme.text};
    background-color: #FDE9E9
}

@media (max-width: 768px) {
    border: 1px solid ${props => props.theme.text};
padding: 1rem;
height: auto;
width: 60vw;
// height: 40vh;

z-index:0;
line-height: 1.5;
cursor: pointer;

}

`

const Title = styled.h2`
display: flex;
justify-content: center;
align-items: center;
font-size: calc(1em + 1vw);

${Main}:hover &{
    &>*{
        // fill:${props => props.theme.body};
    }
}

&>*:first-child{
margin-right: 1rem;
}
`

const Description = styled.div`
color: ${props => props.theme.text};
font-size: calc(0.40em + 1vw);
padding: 0.5rem 0;
height: auto;



${Main}:hover &{
   
        color:${props => props.theme.body};
    
}

strong{
    // margin-bottom: 1rem;
    text-transform: uppercase;
}
ul,p{
    text-align: justify; 

    margin-left: 1rem;
}
`

const MySkillsPage = () => {
    return (
        <ThemeProvider theme={lightTheme}>

            
<Box>
<BigTitlte2 text="SKILLS" top="35%"/>

<LogoComponent theme='light'/>
<SocialIcons theme='light'/>
<PowerButton />

<ParticleComponent theme='light' />
            <Main>
<Title>
    <Design width={40} height={40} /> Technical Skills
</Title>
<Description>
{/* <strong>SKILLS</strong> */}
    <ul>

    <li>
    Proficient in Python, TensorFlow, and PyTorch for deep learning model development.
    </li>
    <li>
    Strong grasp of data preprocessing, feature engineering, and data visualization techniques.
    </li>
    <li>
    Expertise in creating streamlit app for machine learning models.
    </li>
    <li>
    Skilled in natural language processing (NLP) for text analysis and sentiment analysis.

    </li>
    <li>
    Experience with time series forecasting.
    </li>
</ul>
</Description>
{/* <Description>
<strong>Tools</strong>
<ul>
    <li>
       Figma
    </li>
    
</ul>
</Description> */}

            </Main>
            <Main>
<Title>
    <Develope width={40} height={40} /> Data Analysis
</Title>
<Description>
<ul>
        <li>Data cleaning, transformation, and manipulation using pandas and NumPy.</li>
        <li>Statistical analysis and hypothesis testing to derive actionable insights.</li>
        <li>Knowledge of SQL for querying databases and data extraction.</li>
        <li>Familiarity with big data tools like Dask for handling large datasets.</li>
    </ul>

</Description>
            </Main>

            <Main>
<Title>
    <Developement width={40} height={40} />Model Development
</Title>
{/* <Description>
These are some Data Analysis skills I know of:
</Description> */}
<Description>
{/* <strong>SKILLS</strong> */}
<ul>
        <li>Building, training, and fine-tuning machine learning models for classification, regression, and clustering.</li>
        <li>Cross-validation, hyperparameter tuning, and model evaluation using metrics like ROC AUC, F1-score, and MAE.</li>
        <li>Implementing deep learning architectures, including CNNs, RNNs.</li>
        <li>Experience with model interpretability and explainability techniques.</li>
    </ul>

</Description>
            </Main>

            <Main>
<Title>
    <Additional_Skills width={40} height={40} /> Additional Skills
</Title>
<Description>
<ul>
        <li>Strong problem-solving and analytical thinking.</li>
        <li>Excellent debugging and troubleshooting abilities.</li>
        <li>Solid understanding of software engineering principles.</li>
        <li>Familiarity with version control systems like Git.</li>
        <li>Ethical AI practices and data privacy awareness.</li>
    </ul>

</Description>
            </Main>


            {/* <BigTitle2 text="SKILLS" top="77%" /> */}

        </Box>

        </ThemeProvider>
        
    )
}

export default MySkillsPage
