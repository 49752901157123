import { motion } from "framer-motion";

import React from "react";

import styled from "styled-components";

import { Facebook, Github, Twitter } from "../components/AllSvgs";  

import { DarkTheme } from "../components/Themes";

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 2rem;
  z-index: 3;



  & > *:not(:last-child) {
    margin: 0.5rem 0;
  }
  @media (max-width: 768px) {
    left: 1rem; 

    & > *:not(:last-child) {
      margin: 0.4rem 0;
    }
  }

`

const Line = styled(motion.span)`

  width: 2px;
  background-color: ${(props) =>
    props.color === "dark" ? DarkTheme.text : DarkTheme.body};

    @media (max-width: 768px) {
      width: 5%;

      /* Adjust styles for screens up to 768px wide */
      font-size: 1.5rem;
  }
`;

const SocialIcons = (props) => {
  const icon_width = window.innerWidth <= 768 ? '20' : '25';

  return (
    <Icons>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1 }}
      >
        <a
          style={{ color: "inherit" }}
          target="_blank"
          href={"https://github.com/HassyP"}
        >
          <Github
            width={icon_width}
            height={icon_width}
            fill={props.theme === "dark" ? DarkTheme.text : DarkTheme.body}
          />
        </a>
      </motion.div>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1.2 }}
      >
        <a
          style={{ color: "inherit" }}
          target="_blank"
          href={"https://wa.me/+923366484389"}
        >
          <Twitter
            width={icon_width}
            height={icon_width}
            fill={props.theme === "dark" ? DarkTheme.text : DarkTheme.body}
          />
        </a>
      </motion.div>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1.4 }}
      >
        <a
          style={{ color: "inherit" }}
          target="_blank"
          href={"https://www.linkedin.com/in/hasseeb-ahmad/"}
        >
          <Facebook
            width={icon_width}
            height={icon_width}
            fill={props.theme === "dark" ? DarkTheme.text : DarkTheme.body}
          />
        </a>
      </motion.div>

      <Line
        color={props.theme}
        initial={{
          height: 0,
        }}
        animate={{
          height: "8rem",
        }}
        transition={{
          type: "spring",
          duration: 1,
          delay: 0.8,
        }}
      />
    </Icons>
  );
};

const ResponsiveSocialIcons = styled(SocialIcons)`
  @media (max-width: 768px) {
    left: 1%;
  }
`;

export default ResponsiveSocialIcons;
