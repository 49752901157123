// work data

export const Work = [
    {
        id: 1,
        name: "Classification of Wireless Attacks in IDS using ML",
        description: "Multi-Attacks Classification in IDS using ML",
        tags: ["python", "sklearn", "ml", "cybersecurity"],

        demo: "https://classification-of-wireless-attacks.streamlit.app/",
        github: "https://classification-of-wireless-attacks.streamlit.app/"
    },
    {
        id: 2,
        name: "Movie Recommendation System",
        description: "Movie Recommender",
        tags: ["python", "ml", "movie recommender"],

        demo: "https://movie-recommender-hasseeb.streamlit.app/",
        github: "https://github.com/HassyP/Movie-Recommender"
    },
    {
        id: 3,
        name: "Named Entity Recognition (NER) with Machine Learning",
        description: "Named Entity Recognition (NER) Project",
        tags: ["python", "tensorflow", "ml"],

        demo: "https://github.com/HassyP/ML_Named_Entity_Recognition/tree/main",
        github: "https://github.com/HassyP/ML_Named_Entity_Recognition/tree/main"
    },
    {
        id: 4,
        name: "Time Series Forecasting",
        description: "Time Series Forecasting using XGBoost Regressor",
        tags: ["python", "xgboost", "ml"],

        demo: "https://github.com/HassyP/ML_Time_Series_Forecasting/tree/main",
        github: "https://github.com/HassyP/ML_Time_Series_Forecasting/tree/main"
    },
    {
        id: 5,
        name: "Learning Management System",
        description: "LMS for my University using Odoo",
        tags: ["python", "odoo", "postgresql"],

        demo: "https://youtu.be/qSx7lJ8dkyE",
        github: "https://youtu.be/qSx7lJ8dkyE"
    },
    {
        id: 6,
        name: "Email Spam Classification",
        description: "Email Spam Classifier",
        tags: ["python", "ml", "j48"],

        demo: "https://github.com/HassyP/ML-Email-Spam-Classification#email-spam-classification",
        github: "https://github.com/HassyP/ML-Email-Spam-Classification#email-spam-classification"
    },


    // {
    //     id:4,
    //     name:"Sidebar Menu",
    //     description:"A cool looking sidebar menu build using ReactJS and styledComponents.It has really smooth animations.",
    //     tags:["react","styledComponents","react-router"],

    //     demo:"https://react-sidebar.vercel.app/",
    //     github:"https://github.com/codebucks27/react-sidebar"

    // },{
    //     id:5,
    //     name:"Todo App(React + Redux)",
    //     description:"A todo app build using Redux-toolkit and framer-motion for smooth animations.",
    //     tags:["react","redux","framer-motion"],

    //     demo:"http://react-redux-todo-app-git-main-codebucks27.vercel.app/",
    //     github:"https://github.com/codebucks27/React-Redux-Todo-App"

    // },
    // {
    //     id:6,
    //     name:"Responsive Navigation Menu",
    //     description:"A reponsive navbar menu component in ReactJS with react-router functionalities. Built from scratch.",
    //     tags:["react","react-router","css"],

    //     demo:"http://react-responsive-navbar-codebucks27.vercel.app/",
    //     github:"https://github.com/codebucks27/React-responsive-navbar"

    // },


] 
