import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Me from '../assets/Images/profile-img.png'


const Box = styled(motion.div)`

position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);


width: 65vw;
height: 65vh;
display: flex;

@media (max-width: 768px) {
    width: 75vw;
height:15vh;
// display: flex;
}

background: linear-gradient(
    to right,
    ${props => props.theme.body} 50%,
    ${props => props.theme.text} 50%) bottom,
    linear-gradient(
    to right,
    ${props => props.theme.body} 50%,
    ${props => props.theme.text} 50%) top;
    background-repeat: no-repeat;
background-size: 100% 2px;
    border-left: 2px solid ${props => props.theme.body};
    border-right: 2px solid ${props => props.theme.text};


    z-index:1;

`
const SubBox = styled.div`
width: 50%;
position: relative;
display: flex;

.pic{
    position: absolute;
    bottom: 0.5%;
    left: 50%;
    transform: translate(-50%,0%);
    width: 70%;
    height: auto;
    filter: drop-shadow(0 10px 50px rgba(0, 0, 0, 1));

}

@media (max-width: 768px) {
    .pic{
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translate(-50%,0%);
        width: 95%;
        height: auto;
        filter: drop-shadow(0 10px 50px rgba(0, 0, 0, 1));
    
    }
}
`

const Text = styled.div`
font-size: calc(1em + 1.5vw);
color: ${props => props.theme.body};
padding: 2rem;
cursor: pointer;

display: flex;
flex-direction: column;
justify-content: space-evenly;

&>*:last-child{
    color: ${props => `rgba(${props.theme.bodyRgba},0.6)`};
    font-size: calc(0.5rem + 1vw);
    font-weight:300;

}



`



const Intro = () => {
    const animateHeight = window.innerWidth <= 768 ? '30%' : '55%';

    return (
        <Box
            initial={{ height: 0 }}
            animate={{ height: animateHeight }} // Dynamic height value based on screen width
            transition={{ type: 'spring', duration: 2, delay: 1 }}
        >
            <SubBox>
                <Text>
                    <h1>Hi,</h1>
                    <h3>I'm Hasseeb Bhutta.</h3>
                    <h6>I am a MS Computer Science student and Machine Learning Engineer.</h6>
                </Text>
            </SubBox>
            <SubBox>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 2 }}
                >
                    <img className="pic" src={Me} alt="Profile Pic" />
                </motion.div>
            </SubBox>
        </Box>
    )
}

export default Intro
