import React from 'react'
import styled from 'styled-components'

const Text2 = styled.h1`
position: fixed;
// top: ${props => props.top || '50%'};
top: 50%;
left: 50%;
transform: translate(-50%, -50%) rotate(-90deg); /* Rotate the text */
font-size: calc(2.5em + 2vw);
font-weight: bold;
color:${props => `rgba(${props.theme.textRgba},0.3)`};
z-index: 2;

  @media (max-width: 768px) {
    left: 6%;

    font-size: calc(2rem + 3vw); /* Adjust font size for smaller screens */
  }
`

const BigTitlte2 = (props) => {
    return (
        <Text2 top={props.top} left={props.left} right={props.right} rotation="-90deg">
            {props.text}
        </Text2>
    )
}

export default BigTitlte2
