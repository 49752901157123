import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import LogoComponent from '../subComponents/LogoComponent'
import PowerButton from '../subComponents/PowerButton'
import SocialIcons from '../subComponents/SocialIcons'
import { YinYang } from './AllSvgs'
import Intro from './Intro'
    ;


const MainContainer = styled.div`
background: ${props => props.theme.body};
width: 100vw;
height: 100vh;
overflow:hidden;

position: relative;

h2,h3,h4,h5,h6{
  font-family:'Karla', sans-serif ;
  font-weight:500;
}
`

const Container = styled.div`
padding: 2rem;
`

const Contact = styled.a`
color: ${props => props.theme.text};
position: absolute;
top: 2rem;
right: calc(1rem + 2vw);
text-decoration: none;
z-index:1;
`
const BLOG = styled(NavLink)`
color: ${props => props.theme.text};
position: absolute;
top: 50%;
right: calc(1rem + 2vw);
transform: rotate(90deg) translate(-50%, -50%);
text-decoration: none;
z-index:1;
`
const WORK = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text};
text-decoration: none;
z-index:1;
// color: ${props => props.click ? props.theme.body : props.theme.text};
// color: ${props => props.click ? props.theme.wh_clr : props.theme.wh_clr};
// position: absolute;
// top: 50%;
// left: calc(1rem + 2vw);
// transform: translate(-50%, -50%) rotate(-90deg) ;
// text-decoration: none;
// z-index:1;
`

const BottomBar = styled.div`
position: absolute;
bottom: 1rem;
left: 0;
right: 0;
width: 100%;

display: flex;
justify-content: space-evenly;
`

const ABOUT = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text};
// color: ${props => props.click ? props.theme.wh_clr : props.theme.wh_clr};

text-decoration: none;
z-index:1;
`
const SKILLS = styled(NavLink)`
color: ${props => props.theme.text};
// color: ${props => props.click ? props.theme.wh_clr : props.theme.wh_clr};

text-decoration: none;
z-index:1;
`
const RESUME = styled(NavLink)`
color: ${props => props.theme.text};
// color: ${props => props.click ? props.theme.wh_clr : props.theme.wh_clr};

text-decoration: none;
z-index:1;
`
const rotate = keyframes`
from{
    transform: rotate(0);
}
to{
    transform: rotate(360deg);
}
`

const Text = styled.div`
font-size: calc(0.5em + 1vw);
// font-weight: 500;
// color: white;
// padding: 0.5rem;
cursor: pointer;

display: flex;
flex-direction: column;
justify-content: space-evenly;

&>*:last-child{
    color: ${props => `rgba(255,255,255,1)`};
    font-size: calc(0.5rem + 1vw);
    font-weight:400;

}
`

const Text2 = styled.div`
font-size: calc(0.5em + 1vw);
// font-weight: 500;
color: ${props => props.click ? props.theme.body : props.theme.text};
// padding: 0.5rem;
cursor: pointer;

display: flex;
flex-direction: column;
justify-content: space-evenly;

&>*:last-child{
    color: ${props => props.click ? props.theme.body : props.theme.text};
    font-size: calc(0.5rem + 1vw);
    font-weight:700;

}
`




const Center = styled.button`
position: absolute;
top: ${props => props.click ? '85%' : '50%'};
left: ${props => props.click ? '92%' : '50%'};
transform: translate(-50%,-50%);
border: none;
outline: none;
background-color: transparent;
cursor: pointer;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
transition: all 1s ease;

&>:first-child{

    width: 150%;
    height: 150%;
    animation: ${rotate} infinite 2.5s linear;
}

@media (max-width: 768px) {
    transform: translate(-50%,-50%);

    top: ${props => props.click ? '85%' : '50%'};

    left: ${props => props.click ? '87%' : '50%'};

    &>:first-child{
        
        width: 300%;
        // height: 250%;
        animation: ${rotate} infinite 2.5s linear;
    }
}

&>:last-child{
    display: ${props => props.click ? 'none' : 'inline-block'};
    padding-top: 1rem;
}
`

const DarkDiv = styled.div`
position: absolute;
top: 0;
background-color: #000;
bottom: 0;
right: 50%;
width: ${props => props.click ? '50%' : '0%'};
height: ${props => props.click ? '100%' : '0%'};
z-index:1;
transition: height 0.5s ease, width 1s ease 0.5s;
`


const Main = () => {
    const yinyang_width = window.innerWidth <= 768 ? '700%' : '50%';
    const yinyang_width2 = window.innerWidth <= 768 ? '700%' : '50%';

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <MainContainer>
            <DarkDiv click={click} />
            <Container>
                <PowerButton />
                <LogoComponent theme={click ? 'dark' : 'light'} />
                <SocialIcons theme={click ? 'dark' : 'light'} />

                <Center click={click}>
                    <YinYang onClick={() => handleClick()} width={click ? yinyang_width : yinyang_width2} height={click ? yinyang_width : yinyang_width2} fill='currentColor' />
                    <span></span>
                </Center>

                <Contact target="_blank" href="mailto:hasseeb373@gmail.com">
                    <motion.h2
                        initial={{
                            y: -200,
                            transition: { type: 'spring', duration: 1.5, delay: 1 }
                        }}
                        animate={{
                            y: 0,
                            transition: { type: 'spring', duration: 1.5, delay: 1 }
                        }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}

                    >
                        <Text2><h1>Contact Me.</h1></Text2>
                    </motion.h2>
                </Contact>
                {/* <BLOG to="/blog">
                <motion.h2
                initial={{
                    y:-200,
                    transition: { type:'spring', duration: 1.5, delay:1}
                }}
                animate={{
                    y:0,
                    transition: { type:'spring', duration: 1.5, delay:1}
                }}
                whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
                >
                    Blog
                </motion.h2>
            </BLOG> */}
                {/* <WORK to="/work" click={+click}>
                <motion.h2
                initial={{
                    y:-200,
                    transition: { type:'spring', duration: 1.5, delay:1}
                }}
                animate={{
                    y:0,
                    transition: { type:'spring', duration: 1.5, delay:1}
                }}
                 whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
                >
                    Work
                </motion.h2>
            </WORK> */}
                <BottomBar>
                    <WORK to="/work" click={+click}>
                        <motion.h2
                            initial={{
                                y: 200,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            animate={{
                                y: 0,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Text2><h1>Projects.</h1></Text2>


                        </motion.h2>
                    </WORK>

                    <ABOUT to="/about" click={+click}>
                        <motion.h2
                            initial={{
                                y: 200,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            animate={{
                                y: 0,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Text2><h1>About.</h1></Text2>
                        </motion.h2>
                    </ABOUT>
                    <SKILLS to="/skills">
                        <motion.h2
                            initial={{
                                y: 200,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            animate={{
                                y: 0,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Text2><h1>My Skills.</h1></Text2>
                        </motion.h2>
                    </SKILLS>
                    {/* https://drive.google.com/uc?id=1xVGqEePFwTUTUqALYorFxwwsiDx3kBQ9&export=download */}
                    <RESUME to="">
                        <motion.h2
                            initial={{
                                y: 200,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            animate={{
                                y: 0,
                                transition: { type: 'spring', duration: 1.5, delay: 1 }
                            }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Text2><h1>Resume.</h1></Text2>
                        </motion.h2>
                    </RESUME>
                </BottomBar>

            </Container>
            {click ? <Intro click={click} /> : null}
        </MainContainer>
    )
}

export default Main
