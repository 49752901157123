import React, { useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import music from "../assets/audio/u-said-it-v13-1167.mp3"

const Box = styled.div`
display:flex;
cursor:pointer;
position:fixed;
left:10%;
top: 7%;
padding-left: 20px;


@media (max-width: 768px) {
    left:10%;
    top: 5.5%;
}

@media (max-width: 480px) {
    left:10%;
    top: 5.5%;
}

z-index:10;

&>*:nth-child(1){
    animation-delay: 0.2s;
}
&>*:nth-child(2){
    animation-delay: 0.3s;
}
&>*:nth-child(3){
    animation-delay: 0.4s;
}
&>*:nth-child(4){
    animation-delay: 0.5s;
}
&>*:nth-child(5){
    animation-delay: 0.8s;
}
`

const play = keyframes`
0%{
    transform:scaleY(1);
}
50%{
    transform:scaleY(2);
}
100%{
    transform:scaleY(1);
}
`

const Line = styled.span`
    background: ${props => props.theme.text};
    border: 1px solid ${props => props.theme.body};
    animation: ${play} 1s ease infinite;
    animation-play-state: ${props => (props.click ? 'running' : 'paused')};
    height: 1rem;
    width: 2px;
    margin: 0 0.1rem;

    @media (max-width: 768px) {
        /* Adjust styles for screens up to 768px wide */
        height: 0.8rem;
        width: 1px;
        margin: 0 0.05rem;
    }

    @media (max-width: 480px) {
        /* Further adjustments for screens up to 480px wide */
        height: 0.6rem;
        width: 1px;
        margin: 0 0.03rem;
    }
`;

const SoundBar = () => {

    const ref = useRef(null);
    const [click, setClick] = useState(false);

    const handleClick = () => {
        setClick(!click);

        if(!click){
            ref.current.play();
        }else{
            ref.current.pause();
        }
    }
    return (
        <Box onClick={() => handleClick()}>
            <Line click={click}/>
            <Line click={click}/>
            <Line click={click}/>
            <Line click={click}/>
            <Line click={click}/>


            <audio src={music} ref={ref}  loop />
        </Box>
    )
}

export default SoundBar
