import React from 'react'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { DarkTheme } from './Themes';


import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitlte'
import astronaut from '../assets/Images/spaceman.png'

const Box = styled.div`
background-color: ${props => props.theme.body};
// background-color: red;
width: 100vw;
height:100vh;
position: relative;
overflow: hidden;
`
const float = keyframes`
0% { transform: translateY(-10px) }
50% { transform: translateY(15px) translateX(15px) }
100% { transform: translateY(-10px) }

`
const Spaceman = styled.div`
position: absolute;
top: 4%;
right: 5%;
width: 5vw;
animation: ${float} 4s ease infinite;
img{
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    width: 15%;

    top: 5%;
right: 10%;
}
`
const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  width: 80vw;
  height: 59vh;
  z-index: 3;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: calc(0.4rem + 1vw);
 backdrop-filter: blur(4px);
  
  position: absolute;
  left: calc(5rem + 5vw);
  top: 10rem;
  text-align: justify;

  font-family: 'Ubuntu Mono', monospace;
  font-style: italic;

  @media (max-width: 768px) {
    position: absolute;
    font-size: 70%;

    // left: 50rem;
    height: 65%;

    width: 60%;
    left: 15vw;
    top: 20%;
  }
`




const AboutPage = () => {
    return (
        <ThemeProvider theme={DarkTheme}>
            <Box>

                <LogoComponent theme='dark' />
                <SocialIcons theme='dark' />
                <PowerButton />
                <ParticleComponent theme='dark' />

                <Spaceman>
                    <img src={astronaut} alt="spaceman" />
                </Spaceman>
                <Main>
                    <p>Hello, I'm Hasseeb Ahmad, a Computer Science enthusiast with a strong academic foundation and a burning curiosity for cutting-edge technologies. I hold a Bachelor's degree in Computer Science and am currently on a transformative journey as I pursue my Master's in Computer Science from NUST, where I'm delving deeper into the realms of artificial intelligence, data analysis, and advanced computing.
                        <br></br>
                        <br></br>

                        🌟 What I Bring:
                        I'm eager to leverage my skills and knowledge to contribute meaningfully to an organization's success. I believe in the power of collaboration, innovation, and lifelong learning. I'm committed to staying at the forefront of technological advancements and applying my expertise to drive positive change.
                        <br></br>
                        <br></br>

                        🔍 What I'm Seeking:
                        I am actively seeking opportunities to work in an environment that fosters innovation, encourages growth, and values the collective power of a diverse team. I aspire to contribute my skills and passion for technology to enhance an organization's capabilities while continuously honing my own skills.
                    </p>
                </Main>

                <BigTitle text="ABOUT" top="10%" left="5%" />


            </Box>

        </ThemeProvider>

    )
}

export default AboutPage
